var decryptUsingRSA = function(cipherText, privateKey) {
  function stripLineFeeds(str) {
    if (!str) str = "";
    //  var re = RegExp(String.fromCharCode(13),'g');//\r
    //  var re = RegExp(String.fromCharCode(10),'g');//\n
    var s = "";
    s = str.replace(/\n/g, "");
    s = s.replace(/\r/g, "");
    return s;
  }

  function certParser(cert) {
    var lines = cert.split("\n");
    var read = false;
    var b64 = false;
    var end = false;
    var flag = "";
    var retObj = {};
    retObj.info = "";
    retObj.salt = "";
    retObj.iv = "";
    retObj.b64 = "";
    retObj.aes = false;
    retObj.mode = "";
    retObj.bits = 0;
    for (var i = 0; i < lines.length; i++) {
      flag = lines[i].substr(0, 9);
      if (
        i == 1 &&
        flag != "Proc-Type" &&
        flag.indexOf("M") == 0 //unencrypted cert?
      )
        b64 = true;
      switch (flag) {
        case "-----BEGI":
          read = true;
          break;
        case "Proc-Type":
          if (read) retObj.info = lines[i];
          break;
        case "DEK-Info:":
          if (read) {
            var tmp = lines[i].split(",");
            var dek = tmp[0].split(": ");
            var aes = dek[1].split("-");
            retObj.aes = aes[0] == "AES" ? true : false;
            retObj.mode = aes[2];
            retObj.bits = parseInt(aes[1]);
            retObj.salt = tmp[1].substr(0, 16);
            retObj.iv = tmp[1];
          }
          break;
        case "":
          if (read) b64 = true;
          break;
        case "-----END ":
          if (read) {
            b64 = false;
            read = false;
          }
          break;
        default:
          if (read && b64) retObj.b64 += stripLineFeeds(lines[i]);
      }
    }
    return retObj;
  }

  function decodeBase64(str, utf8decode) {
    if (!str) str = "";
    var b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    utf8decode = typeof utf8decode == "undefined" ? false : utf8decode;
    var o1,
      o2,
      o3,
      h1,
      h2,
      h3,
      h4,
      bits,
      d = [],
      plain,
      coded;
    let pidCryptUtil = {};
    coded = utf8decode ? pidCryptUtil.decodeUTF8(str) : str;

    for (var c = 0; c < coded.length; c += 4) {
      // unpack four hexets into three octets
      h1 = b64.indexOf(coded.charAt(c));
      h2 = b64.indexOf(coded.charAt(c + 1));
      h3 = b64.indexOf(coded.charAt(c + 2));
      h4 = b64.indexOf(coded.charAt(c + 3));

      bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4;

      o1 = (bits >>> 16) & 0xff;
      o2 = (bits >>> 8) & 0xff;
      o3 = bits & 0xff;

      d[c / 4] = String.fromCharCode(o1, o2, o3);
      // check for padding
      if (h4 == 0x40) d[c / 4] = String.fromCharCode(o1, o2);
      if (h3 == 0x40) d[c / 4] = String.fromCharCode(o1);
    }
    plain = d.join(""); // join() is far faster than repeated string concatenation

    plain = utf8decode ? pidCryptUtil.decodeUTF8(plain) : plain;

    return plain;
  }

  function toByteArray(str) {
    if (!str) str = "";
    var ba = [];
    for (var i = 0; i < str.length; i++) ba[i] = str.charCodeAt(i);

    return ba;
  }

  function convertToHex(str) {
    if (!str) str = "";
    var hs = "";
    var hv = "";
    for (var i = 0; i < str.length; i++) {
      hv = str.charCodeAt(i).toString(16);
      hs += hv.length == 1 ? "0" + hv : hv;
    }
    return hs;
  }

  var asn = require("./asn.js");
  var rsa = require("./rsa.js");

  // var privateKey =
  //   "-----BEGIN RSA PRIVATE KEY-----\n" +
  //   "MIICWwIBAAKBgQDONZuwbitj2WVra1m+vCQ7PQfQjjhas+9jV8qADb1sjoV03moC\n" +
  //   "itvWJICQkWUgdQYXdYAQHv4/qKAh0lSMZboaI47BnmUkBpExf8YxpZTqJ8qsZCQW\n" +
  //   "MaYqq4kF9Rvy9S+SmF/LD1P7kG7jZtspC/Iqs0GHsZdLEtdujIFHB3m9nwIDAQAB\n" +
  //   "AoGACgZNJUyVy2rcJVa4rC8VS9Pxt0/SqYYp0xiHfaYtWUOsdTwCx1Dc0xI/Ncf7\n" +
  //   "tJI705Qgv6fiEFIwRrSk65Zeb6R2J0RU6CmugJhFQapRqlp3nJHmLZUnwud3DSdm\n" +
  //   "0VCT/+4NhqRr2BkDMyTx8gebMelu6DacHmF1Q0M76Tf0UuECQQD4psVD8Qud/x/A\n" +
  //   "utmiQyiaKSeznGyyGiVkJ4mZ1UfTnHpiFm2ay+febVUYGN3X/yL1Ot9xCDqrtT1O\n" +
  //   "fdpRtPApAkEA1E27jQ+dvqdrrYokyGUlqR0tJU0MEBJdFbok5c3sQ7LfesoxbULF\n" +
  //   "r33EStCEnCWwwF7pYDDP/rSI8jpZRplYhwJAGwV0loeRbaAWAUnM8Nc/iFFEoT40\n" +
  //   "ib5RpequB2uoHJT6r8IkMxhJoGBdSPsa0KhHUkMNmSj9xz8w5GDE4vA40QJAchwJ\n" +
  //   "t4ryNVxVUuRSWueKs3f8IPVli2njYF85n87rs4N7/MqagGiISoflrnSfIjJb6znY\n" +
  //   "ZBw4X2V3ygI0o+6eFwJAUFRrHtnNBLmAjWn4CsCrU42il8jrRuSlIiEiBjCDdrB8\n" +
  //   "6RxmdxUr9SKrFI0BvUDa2h07eKe1oXEYdaihOlXNDA==\n" +
  //   "-----END RSA PRIVATE KEY-----";

  var RSA = new rsa();
  var params = certParser(privateKey);
  var decryptionKey = decodeBase64(params.b64);
  asn = asn.decode(toByteArray(decryptionKey));
  var tree = asn.toHexTree();
  RSA.setPrivateKeyFromASN(tree);
  cipherText = decodeBase64(stripLineFeeds(cipherText));
  return RSA.decrypt(convertToHex(cipherText));
};

var decryptUsingAES = function(cipherText, symmetricKey) {
  var CryptoJS = require("node-cryptojs-aes").CryptoJS;
  var plainText = CryptoJS.AES.decrypt(cipherText, symmetricKey);
  return CryptoJS.enc.Utf8.stringify(plainText);
};

var getDecryptedParams = (params, key) => {
  var keyInEncryptedBody = "rq";
  if (params && params[keyInEncryptedBody + "k"] && params[keyInEncryptedBody]) {
    var aesKey = decryptUsingRSA(params[keyInEncryptedBody + "k"], key);
    var decryptedParams = decryptUsingAES(params[keyInEncryptedBody], aesKey);
    delete params[keyInEncryptedBody + "k"];
    delete params[keyInEncryptedBody];
    try {
      decryptedParams = JSON.parse(decryptedParams);
    } catch (e) {}
    params = Object.assign({}, params, decryptedParams);
  }
  return params;
};

module.exports = {
  getDecryptedParams
};
