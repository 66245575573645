import update from 'react-addons-update';
const initialState = {
    data: [],
    user: {},
    sortQuery: { _createdOn: -1 },
    addOnFilter: {},
    noOfPages: [],
    count: 0,
    value: '',
    location: {},
    loading: false,
    scheduleUserData: [],
    errorMessage: "",
    globalValidation: { valid: false, message: '' }
};
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER': 
            return { ...state, user: action.payload }
        case 'GET_USER_DATA':
            return { ...state, loading: true };
        case 'UPDATE_USER_DATA':
            {
                let { data = [] } = action;
                let newData = {};
                data && data.map(item => {
                    let { id, ...remaining } = item;
                    newData[item.id] = { ...remaining }
                })
                if (action.value || action.value === "") {

                    return { ...state, data: newData, noOfPages: action.noOfPages, count: action.count, addOnFilter: action.addOnFilter, sortQuery: action.sortQuery, value: action.value, loading: false };
                }
                else {

                    return { ...state, data: newData, noOfPages: action.noOfPages, count: action.count, addOnFilter: action.addOnFilter, sortQuery: action.sortQuery, loading: false };
                }
            }
        case 'UPDATE_SORT_QUERY':
            return { ...state, sortQuery: action.payload.sortQuery };
        case 'UPDATE_SCHEDULE_USER_DATA':
            return { ...state, scheduleUserData: action.data }
        case 'USER_REQUEST_FAILED':
            let { errorMessage = "" } = action;
            return { ...state, errorMessage }
        case 'ACTIVATE_DEACTIVATE':
            let id = action.id;
            let status = action.status;
            let collection = state.data;
            let newCollection = update(collection, { [id]: { status: { $set: status } } })
            return { ...state, data: newCollection }
        case 'ADD_USER':
            {
                let { id, ...remaining } = action.payload.data
                return { ...state, data: { [id]: { ...remaining }, ...state.data } }
            }
        case 'EDIT_USER':
            {
                let { id, ...remaining } = action.payload.data
                return { ...state, data: { ...state.data, [id]: { ...remaining } } }
            }
        default:
            return state;
    }
};
export default userReducer;