import { withRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import * as firebase from "firebase";
import React, { Component } from "react";
import _ from "lodash";
import "firebase/messaging";

import Store from "./redux/Store";
import {
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseDatabaseUrl,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseMessagingSenderId,
  firebaseAppId,
  firebaseMeasurementId,
  firebaseNotificationKeyPair,
} from "./utilities/config";
import "./App.scss";
import AsyncStorage from "./utilities/encryption/AsyncStorage";

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  databaseURL: firebaseDatabaseUrl,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
  measurementId: firebaseMeasurementId,
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading,
});
const ForgotPassword = Loadable({
  loader: () => import("./views/Pages/ForgotPassword"),
  loading,
});
const ResetPassword = Loadable({
  loader: () => import("./views/Pages/ResetPassword"),
  loading,
});
const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading,
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading,
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading,
});

const checkRunningStatus = Loadable({
  loader: () => import("./views/Pages/RunningStatus"),
  loading
});

class App extends Component {
  getToken = () => {
    this.messaging
      .getToken()
      .then((refreshedToken) => {
        this.notificationToken = refreshedToken;
        Store.dispatch({
          type: "SET_NOTIFICATION_TOKEN",
          payload: { token: refreshedToken },
        });
        Store.dispatch({
          type: "REGISTER_TOKEN",
        });
      })
      .catch((err) => {
        // console.log("@@@@ err >>>>>> ", err);
      });
  };

  onNotificationClose = () => {
    this.notification = void 0;
  };

  showNotification = ({ data, notification }) => {
    const { history } = this.props;
    const { href } = window.location;
    const splitLocation = href.split("?");
    let notificationToBeShow = true;
    if (
      splitLocation[0].indexOf("groupChat") > -1 &&
      data.uri === "/chat" &&
      data.id &&
      data.channel_id
    ) {
      let channelInfo = data.channel_id.split("-");
      const info = splitLocation[1].split("&&&");
      if (
        info[0] === channelInfo[0] &&
        info[1] === channelInfo[1] &&
        info[2] === data.id
      ) {
        notificationToBeShow = false;
      }
    }
    if (!notificationToBeShow) {
      return;
    }
    if (this.notification) {
      this.notification.removeEventListener("close", this.onNotificationClose);
      this.notification.close();
      this.onNotificationClose();
    }
    let title = notification.title;
    this.notification = new Notification(title, {
      body: notification.body,
      icon: notification.icon,
    });
    this.notification.onclick = (event) => {
      event.preventDefault();
      this.notification.close();
      if (
        (data.uri === "/view-patient-details" || data.uri === "/medication") &&
        data.id
      ) {
        history.push({
          pathname: "/alarmDetail",
          id: data.id,
          search: `?id=${data.id}`,
        });
      } else if (data.uri === "/ems-details" && data.request_id) {
        history.push({
          pathname: "/alarmDetail",
          id: data.request_id,
          search: `?id=${data.request_id}`,
          hash: "#trackEMS",
        });
      } else if (data.uri === "/chat" && data.id && data.channel_id) {
        let channelInfo = data.channel_id.split("-");
        history.push({
          pathname: "/groupChat",
          search: `${channelInfo[0]}&&&${channelInfo[1]}&&&${data.id}`,
        });
      }
    };
    this.notification.addEventListener("close", this.onNotificationClose);
  };

  onMessage = (payload) => {
    const userID = AsyncStorage.getItem("userID");
    const messageFrom = _.get(payload, "data.userId", "");
    if (userID !== messageFrom) {
      this.showNotification(payload);
    }
  };

  componentDidMount() {
    if (firebase.messaging.isSupported()) {
      Notification.requestPermission(() => {
        if (Notification.permission === "granted") {
          this.messaging = firebase.messaging();
          this.messaging.usePublicVapidKey(firebaseNotificationKeyPair);
          this.messaging.onTokenRefresh(this.getToken);
          this.messaging.onMessage(this.onMessage);
          this.getToken();
        }
      });
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route
          exact
          path="/forgotPassword"
          name="ForgotPassword Page"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/resetPassword"
          name="ResetPassword Page"
          component={ResetPassword}
        />
        <Route
          exact
          path="/register"
          name="Register Page"
          component={Register}
        />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Route
          exact
          path="/runningStatus"
          name={"running status"}
          component={checkRunningStatus}
        />
        <Route path="/" name="Home" component={DefaultLayout} />
      </Switch>
    );
  }
}

export default withRouter(App);
