const initialState = {
  notificationToken: "",
};
const alarmDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOTIFICATION_TOKEN":
      const { token } = action.payload;
      return { ...state, notificationToken: token };
    default:
      return state;
  }
};
export default alarmDetailReducer;
