const initialState = {
  data: [],
  // addOnFilter: {},
  // noOfPages: [],
  count: 0,
  // value: '',
  errorMessage: "",
  loading: true,
  notificationCountRes: {},
};
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_UNREAD_NOTIFICATIONS_COUNT": {
      return {
        ...state,
        notificationCountRes: action.data,
      };
    }
    case "NOTIFICATION_SOCKET_EVENT": {
      const { event } = action.data;
      if (event) {
        const { notificationCountRes } = state || {};
        const { aggregates } = notificationCountRes || {};
        let { _count } = aggregates || {};
        _count = _count || 0;
        if (event === "added") {
          _count++;
        } else if (event === "read") {
          _count--;
        }
        return {
          ...state,
          notificationCountRes: {
            ...notificationCountRes,
            aggregates: {
              ...aggregates,
              _count,
            },
          },
        };
      }

      return state;
    }
    case "GET_NOTIFICATION_DATA":
      return { ...state, loading: true };
    case "UPDATE_NOTIFICATION_DATA": {
      let { result, aggregates } = action.data || {};
      result = result || [];
      let count = (aggregates && aggregates._count) || 0;
      if (count) {
        return {
          ...state,
          data: [...result],
          loading: false,
          count: count || 0,
        };
      } else {
        return {
          ...state,
          data: [],
          errorMessage: "No Data Found ",
          loading: false,
        };
      }
    }
    case "NOTIFICATION_UPDATE_FAIL": {
      return {
        ...state,
        data: [],
        loading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
export default notificationReducer;
