const initialAlarmDetailState = {
  errorMessage: "",
  loading: true,
};
const initialEmsDetailState = {
  emsData: {},
  emsLoading: true,
};
const alarmDetailReducer = (
  state = { ...initialAlarmDetailState, ...initialEmsDetailState },
  action
) => {
  switch (action.type) {
    case "GET_ALARM_DETAIL_DATA":
      return { ...state, loading: true };
    case "UPDATE_ALARM_DETAIL_DATA":
      let { data } = action;
      const { assign_ems } = data;
      let newState = {};
      newState = {
        errorMessage: state.errorMessage,
        ...data,
        loading: false,
        emsLoading: !!(assign_ems && assign_ems._id),
      };
      return newState;
    case "ALARM_DETAIL_REQUEST_FAILED":
      let { errorMessage = "" } = action;
      return { ...state, errorMessage, loading: false };
    case "GET_EMS_DATA":
      return { ...state, emsLoading: true };
    case "UPDATE_EMS_DATA":
      return { ...state, emsData: action.data, emsLoading: false };
    case "EMS_REQUEST_FAILED": {
      return { ...state, emsLoading: false };
    }
    case "RESET_EMS_DETAIL_STATE": {
      return { ...state, ...initialEmsDetailState };
    }
    default:
      return state;
  }
};
export default alarmDetailReducer;
