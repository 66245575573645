import createSagaMiddleware from "redux-saga";
import { logger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";

import { callService } from "../utilities/callService";
import AsyncStorage from "../utilities/encryption/AsyncStorage";
import rootSaga from "../redux/sagas";
import reducer from "../redux/reducers";

const sagaMiddleware = createSagaMiddleware();

const setTokenOnServer = (store) => (next) => (action) => {
  const { appInfoReducer } = store.getState();
  const { notificationToken } = appInfoReducer;
  let { type } = action;
  const token = AsyncStorage.getItem("token");
  const role = AsyncStorage.getItem("role");
  if (
    role !== "Admin" &&
    type === "REGISTER_TOKEN" &&
    token &&
    notificationToken
  ) {
    let query = {
      id: "_notificationRegistration",
      paramValue: {
        device: notificationToken,
        type: "web",
      },
      token,
    };
    callService(query)
      .then((res) => {
        // console.log("@@@ res>>>>> ", res);
      })
      .catch((err) => {
        // console.log("@@@ error>>> ", err);
      });
  }
  let result = next(action);
  return result;
};

const Store = createStore(
  reducer,
  applyMiddleware(sagaMiddleware, logger, setTokenOnServer)
);

sagaMiddleware.run(rootSaga);

export default Store;
