import { put, takeLatest, all } from 'redux-saga/effects';
import _ from "lodash"
import { errorFunction } from "../../utilities/errorFunction"
import { callService } from "../../utilities/callService"
import { queryBuilder } from "../../utilities/queryBuilder"

function* fetchLocationData()
{
    try
    {
        let query = queryBuilder("_find", "locations", "Location", {})
        let location = [];
        const response = yield callService(query);
        let locationData = _.get(response, "data.response.result", [])
        let serverKeyMap = { _id: "value", name: "label" }
        if (locationData.length)
        {
            location = locationData.map(item =>
            {
                return _.mapKeys(item, (value, key) =>
                {
                    return serverKeyMap[key];
                });
            })
            location.unshift({ label: "None", value: null })
        }
        yield put({ type: "SET_LOCATION_DATA", location });
    }
    catch (error)
    {
        console.log("error from fetchLocationData", error.response)
        let errorMessage = errorFunction(error);
        yield put({ type: "LOCATION_REQUEST_FAILED", errorMessage });
    }
}
function* actionWatcher()
{
    yield takeLatest('GET_LOCATION_DATA', fetchLocationData)
}
export default function* rootSaga()
{
    yield all([
        actionWatcher(),
    ]);
}