import alarms from "./alarms";
import locations from "./locations";
import users from "./users"
import schedules from "./schedules"
import notification from "./notification"
import appVersion from "./appVersion"
import { all, fork } from "redux-saga/effects";
export default function* root()
{
    yield all([
        fork(alarms),
        fork(locations),
        fork(users),
        fork(schedules),
        fork(notification),
        fork(appVersion),

    ]);
}