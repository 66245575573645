import _ from "lodash";
import { hashHistory } from "./helper"
import AsyncStorage  from "./encryption/AsyncStorage";
export const errorFunction = (error, history) =>
{
    let errorMessage = _.get(error, "response.data.response.error.message", "")
    if (error.message)
    {
        let message = error.message;
        if (message === "Network Error")
        {
            return "Network Error!"
        }
        else if (errorMessage && errorMessage.includes("Invalid token"))
        {
            AsyncStorage.clear();
            hashHistory.push("/login")
        }
        else if (errorMessage === "User already have schedule for same period")
        {
            return "User already have schedule for same period"
        }
        else 
        {
            return "Something went wrong!!"
        }
    }
}