import AsyncStorage from "./encryption/AsyncStorage";
const queryBuilder = (type, queryId, model, extras) => {
    return {
        id: type,
        paramValue: queryId ? {
            query: {
                id: queryId,

                ...extras
            }, model
        } : { ...extras }, token: AsyncStorage.getItem("token")
    }
}

export { queryBuilder };