import { put, takeLatest, all } from 'redux-saga/effects';
import _ from "lodash"
import { errorFunction } from "../../utilities/errorFunction"
import { callService } from "../../utilities/callService"
import AsyncStorage  from "../../utilities/encryption/AsyncStorage";
function* fetchScheduleData(payload)
{
    try
    {
        let { addOnFilter = {} } = payload.payload;
        let query = {
            id: "allUserSchedules",
            paramValue: {
                addOnFilter
            },
            token: AsyncStorage.getItem("token")
        };
        // let query = {
        //     // id: "allUserSchedules",
        //     id: "_find",

        //     paramValue: {
        //         query: {
        //             id: "allSchedules",
        //             addOnFilter: { _id: "5d70e671cfa17264e38b09e3" }
        //             // ...extras
        //         },
        //         model: "Schedule"
        //     },
        //     token: "c0cb47ac4e8af28e17883f22f636570014a5bbf7"
        // };
        // "5d70e671cfa17264e38b09e3"
        let data = [];
        const response = yield callService(query);
        data = _.get(response, "data.response.result", []);
        yield put({ type: "UPDATE_SCHEDULE_DATA", data, errorMessage: "" });
    }
    catch (error)
    {
        // console.log("error from fetchscheduleData", error)
        let errorMessage = errorFunction(error);
        yield put({ type: "SCHEDULE_REQUEST_FAILED", errorMessage });
    }
}
function* saveScheduleData(payload)
{
    try
    {
        let { repeats, repeatOn, endTime: toTime, startTime: fromTime,
            startsOn: fromDate, endsOn: toDate, user: { _id: forUser }, scheduleId } = payload.payload.data
        let { addOnFilter
        } = payload.payload;
        let query
        if (scheduleId)
        {
            query = {
                id: "_save",
                paramValue: {
                    updates: {
                        _updates: {
                            update: {
                                _id: scheduleId,
                                changes: {
                                    repeats,
                                    repeatOn,
                                    startTime: fromTime,
                                    endTime: toTime,
                                    startsOn: fromDate,
                                    endsOn: toDate,
                                    user: { _id: forUser }
                                }
                            }
                        }
                    },
                    model: "Schedule"
                },
                token: AsyncStorage.getItem("token")
            }
        }
        else
        {

            query = {
                id: "_save",
                paramValue: {
                    updates: {
                        _updates: {
                            insert: {
                                repeats,
                                repeatOn,
                                startTime: fromTime,
                                endTime: toTime,
                                startsOn: fromDate,
                                endsOn: toDate,
                                user: { _id: forUser }
                            }
                        }
                    },
                    model: "Schedule"
                }, token: AsyncStorage.getItem("token")
            }
        }
        yield callService(query);
        yield put({ type: "GET_SCHEDULE_DATA", payload: { addOnFilter } });
    }
    catch (error)
    {

        let errorMessage = errorFunction(error);
        yield put({ type: "SAVE_SCHEDULE_REQUEST_FAILED", errorMessage });
    }
}
function* actionWatcher()
{
    yield takeLatest('GET_SCHEDULE_DATA', fetchScheduleData)
}
function* saveWatcher()
{
    yield takeLatest('SAVE_USER_SCHEDULE_DATA', saveScheduleData)
}
export default function* rootSaga()
{
    yield all([
        actionWatcher(), saveWatcher()
    ]);
}