import update from 'react-addons-update';
const initialState = {
    data: [],
    user: {},
    sortQuery: { _createdOn: -1 },
    addOnFilter: {},
    noOfPages: [],
    count: 0,
    value: '',
    location: {},
    loading: false,
    scheduleUserData: [],
    errorMessage: "",
    globalValidation: { valid: false, message: '' }
};
const appVersionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_APP_VERSION': 
            return { ...state, data: action.data }
        case 'GET_APP_VERSION':
            return { ...state, loading: true };
        case 'UPDATE_APP_VERSION_DATA':
            {
                let { data = [] } = action;
                let newData = {};
                data && data.map(item => {
                    let { id, ...remaining } = item;
                    newData[item.id] = { ...remaining }
                })
                if (action.value || action.value === "") {

                    return { ...state, data: newData, loading: false };
                }
                else {

                    return { ...state, data: newData,  loading: false };
                }
            }
        case 'ADD_APP_VERSION':
            {
                let { id, ...remaining } = action.payload.data
                return { ...state, data: { [id]: { ...remaining }, ...state.data } }
            }
        case 'EDIT_APP_VERSION':
            {
                let { id, ...remaining } = action.payload.data
                return { ...state, data: { ...state.data, [id]: { ...remaining } } }
            }
        default:
            return state;
    }
};
export default appVersionReducer;