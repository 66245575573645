import { put, takeLatest, all } from "redux-saga/effects";

import { callService } from "../../utilities/callService";
import { queryBuilder } from "../../utilities/queryBuilder";
import { errorFunction } from "../../utilities/errorFunction";
import AsyncStorage from "../../utilities/encryption/AsyncStorage";
import _ from "lodash";

function* setTCData(payload) {
  try {
    let { tcData } = payload.payload;
    let query = {
      id: "_save",
      paramValue: {
        updates: { _updates: { insert: { discription: tcData } } },
        model: "Terms",
      },
      token: AsyncStorage.getItem("token"),
    };
    yield callService(query);
  } catch (error) {
    console.log("Error>>>>");
  }
}
function* fetchInitialAlarmData(payload) {
  try {
    let {
      skip,
      addOnFilter = {},
      sortQuery = {},
      value,
      limit,
      loadingMore,
    } = payload.payload;
    const role = AsyncStorage.getItem("role");
    const location = JSON.parse(AsyncStorage.getItem("location"));
    if (role === "Referring RN") {
      addOnFilter = {
        ...addOnFilter,
        location: { _id: _.get(location, "_id", "") },
        status: "Accepted",
      };
    }
    let query = queryBuilder("_find", "allRequests", "Request", {
      addOnFilter,
      skip,
      limit,
      metadata: true,
      sort: sortQuery,
    });
    let newData = [];
    let data = [];
    let count;
    const response = yield callService(query);
    data = _.get(response, "data.response.result", []);
    if (data.length) {
      count = _.get(response, "data.response.aggregates._count", 0);
      data.map((item) => {
        let { shock_approval = "N/A" } = item;
        let dataJson = {
          case_id: item.case_id,
          _id: item._id,
          id: item._id,
          reported_by: item.reported_by,
          phone_number: item.phone_number,
          location: _.get(item, "location.fullName", ""),
          date: item.alarm_raise_on,
          shock: item.shock,
          status: item.status,
          shock_approval,
          alarm_raise_on: item.alarm_raise_on,
          toLocation: _.get(item, "toLocation.fullName", ""),
        };
        newData.push(dataJson);
      });
    }
    yield put({
      type: "UPDATE_ALARM_DATA",
      data: newData,
      count: count,
      addOnFilter,
      sortQuery,
      value,
      loadingMore,
    });
  } catch (error) {
    console.log("error from fetInitialAlarmData", error);
    let errorMessage = errorFunction(error);
    yield put({ type: "ALARM_REQUEST_FAILED", errorMessage });
  }
}
function* fetchAlarmDetailData(payload) {
  try {
    let { id } = payload.payload;
    let query = queryBuilder("_find", "requestDetail", "Request", {
      addOnFilter: { _id: id },
    });
    let data = {};
    const response = yield callService(query);
    data = _.get(response, "data.response.result", []);
    yield put({ type: "UPDATE_ALARM_DETAIL_DATA", data });
  } catch (error) {
    console.log("error from fetchAlarmDetialData", error);
    let errorMessage = errorFunction(error);

    yield put({ type: "ALARM_DETAIL_REQUEST_FAILED", errorMessage });
  }
}
function* fetchEMSData(payload) {
  try {
    let { id } = payload.payload;
    let query = queryBuilder("_find", "allEmsTracking", "EmsTracking", {
      addOnFilter: { _id: id },
    });
    let data = {};
    const response = yield callService(query);
    data = _.get(response, "data.response.result", []);
    if (Array.isArray(data)) {
      data = data[0];
      yield put({ type: "UPDATE_EMS_DATA", data });
    } else {
      yield put({ type: "UPDATE_EMS_DATA", data });
    }
  } catch (error) {
    console.log("error from fetchAlarmDetialData", error);
    let errorMessage = errorFunction(error);

    yield put({ type: "EMS_REQUEST_FAILED", errorMessage });
  }
}
function* alarmWatcher() {
  yield takeLatest("GET_ALARM_DATA", fetchInitialAlarmData);
}
function* tcWathcer() {
  yield takeLatest("SET_TC_DATA", setTCData);
}
function* alarmDetailWatcher() {
  yield takeLatest("GET_ALARM_DETAIL_DATA", fetchAlarmDetailData);
}
function* EMSWatcher() {
  yield takeLatest("GET_EMS_DATA", fetchEMSData);
}
export default function* rootSaga() {
  yield all([alarmWatcher(), alarmDetailWatcher(), EMSWatcher(), tcWathcer()]);
}
