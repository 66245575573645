// export const SERVER_URL = "http://192.168.1.51:5000/invoke";
// export const SERVER_URL1 = "http://192.168.1.51:5000";
// export const SERVER_URL = "http://13.235.149.58:5000/invoke";
// export const SERVER_URL1 = "http://13.235.149.58:5000";
// export const SERVER_URL1 = "http://13.235.149.58:5000";

// export const schedulerLicenseKey='0562135641-fcs-1582205081'
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;
export const DOWNLOAD_URL = process.env.REACT_APP_DOWNLOAD_URL;
export const SERVER_URL1 = process.env.REACT_APP_SERVER_URL1;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;
export const pubNubPubKey = process.env.REACT_APP_PUBNUB_PUB_KEY;
export const pubNubSubKey = process.env.REACT_APP_PUBNUB_SUB_KEY;
export const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const firebaseDatabaseUrl = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const firebaseStorageBucket =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const firebaseMessagingSenderId =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
export const firebaseMeasurementId =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const firebaseNotificationKeyPair =
  process.env.REACT_APP_FIREBASE_NOTIFICATION_KEY_PAIR;
export const schedulerLicenseKey = process.env.REACT_APP_schedulerLicenseKey;
export const defultUserImage = process.env.REACT_APP_DEFAULT_USER_IMAGE_KEY;
